import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";

// Sentry.init({
//   dsn: environment.sentry.dsn,
//   release: environment.sentry.release,
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost",
//         "https://*.dft.vn/api/.*",
//         /^https:\/\/creator-donation-service-backend\.dft\.vn\/api/,
//         /^https:\/\/creator-donation-service-backend-staging\.dft\.vn\/api/
//       ],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//     new Sentry.Replay(),
//   ],
//   environment: environment.sentry.environment,

//   enableTracing: true,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.1,
//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });
if (environment.production) {
  enableProdMode();
}

console.log(JSON.stringify(environment))

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
