import { AuthService, ConfigStateService, LocalizationService } from "@abp/ng.core";
import { Injector } from "@angular/core";
import { UserType } from "@proxy/users";
import { MessageService } from "primeng/api";
import { environment } from "src/environments/environment";

export enum WidthType {
  W30 = 0,
  W40,
  W80,
  W100,
  W120,
  W200,
  W250,
  W256,
  W300,
  W512,
  W768,
  W820,
  W1024,
  W1280,
  W1920,
}
export abstract class AppComponentBase {
    localizationService: LocalizationService;
    messageService: MessageService;
    authService: AuthService;
    configStateService: ConfigStateService;
    fileserverUrl = '';
    bucketname = '';

    constructor(injector: Injector) {
        this.localizationService = injector.get(LocalizationService);
        this.messageService = injector.get(MessageService);
        this.authService = injector.get(AuthService);
        this.configStateService = injector.get(ConfigStateService);
        this.fileserverUrl = environment.fileserverUrl;
        this.bucketname = environment.bucketname;
    }

    get hasLoggedIn(): boolean {
        return this.authService.isAuthenticated;
    }

    getCurrentUser() {
        return this.configStateService.getOne('currentUser');
    }

    showCreateSuccessMessage() {
        this.showSuccessMessage(this.L('::Message:CreateMessage'));
    }

    showUpdateSuccessMessage() {
        this.showSuccessMessage(this.L('::Message:UpdateMessage'));
    }

    showDeleteSuccessMessage() {
        this.showSuccessMessage(this.L('::Message:DeleteMessage'));
    }

    showSuccessMessage(message: string) {
        this.messageService.clear('tms');
        this.messageService.add({
            key: 'tms',
            severity: 'success',
            summary: this.localizationService.instant('::Message:Success'),
            detail: message,
        });
    }

    showInfoMessage(message: string) {
        this.messageService.clear('tms');
        this.messageService.add({
            key: 'tms',
            severity: 'info',
            summary: this.localizationService.instant('::Message:Info'),
            detail: message,
        });
    }

    showWarningMessage(message: string) {
        this.messageService.clear('tms');
        this.messageService.add({
            key: 'tms',
            severity: 'warn',
            summary: this.localizationService.instant('::Message:Warn'),
            detail: message,
        });
    }

    showErrorMessage(message: string) {
        this.messageService.clear('tms');
        this.messageService.add({
            key: 'tms',
            severity: 'error',
            summary: this.localizationService.instant('::Message:Error'),
            detail: message,
        });
    }

    L(key: string) {
        return this.localizationService.instant(key);
    }

    logout() {
        this.authService.logout().subscribe(()=>{
            location.reload();
        });
    }

    handlerError(err) {
        debugger
        if (err && err.error.error.message.includes('Your request is not valid!')) {
            this.showErrorMessage(this.L('::CreatorDonate:AddWishList:SomeItemsHaveNotBeenSet'));
        }
        else {
            this.showErrorMessage(err.error.error.message);
        }
    }

    // Convert full-width characters to half-width characters and convert to number
    convertPriceStringToNum(value: any) {
      if (value) {
        // Xóa text ngoại trừ '０-９'
        const numericValue = value.replace(/[^０-９0-9]/g, '');

        return Number(numericValue.replace(/[０-９]/g, (s) => {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
        }));
      }
    }

    convertUrlToBase64(path: string) {
        return btoa(path);
      }

  getUrlImgFromServer(url, type: WidthType) {
    if (url?.startsWith('Upload')) {
      let base64 = this.convertUrlToBase64(this.bucketname + url)
      switch (type) {
        case WidthType.W30:
          return "https://img.gi-pt.com/image/w30/" + base64;
        case WidthType.W40:
          return "https://img.gi-pt.com/image/w40/" + base64;
        case WidthType.W80:
          return "https://img.gi-pt.com/image/w80/" + base64;
        case WidthType.W100:
          return "https://img.gi-pt.com/image/w100/" + base64;
        case WidthType.W120:
          return "https://img.gi-pt.com/image/w120/" + base64;
        case WidthType.W200:
          return "https://img.gi-pt.com/image/w200/" + base64;
        case WidthType.W250:
          return "https://img.gi-pt.com/image/w250/" + base64;
        case WidthType.W256:
          return "https://img.gi-pt.com/image/w256/" + base64;
        case WidthType.W300:
          return "https://img.gi-pt.com/image/w300/" + base64;
        case WidthType.W512:
          return "https://img.gi-pt.com/image/w512/" + base64;
        case WidthType.W768:
          return "https://img.gi-pt.com/image/w768/" + base64;
        case WidthType.W820:
          return "https://img.gi-pt.com/image/w820/" + base64;
        case WidthType.W1024:
          return "https://img.gi-pt.com/image/w1024/" + base64;
        case WidthType.W1280:
          return "https://img.gi-pt.com/image/w1280/" + base64;
        case WidthType.W1920:
          return "https://img.gi-pt.com/image/w1920/" + base64;
      }
    } else {
      return url;
    }
  }

  getUserType(userType?: UserType) {
    switch (userType) {
      case UserType.Creator:
        return this.L('::CreatorDonate:VerifyUserType:Creator');
      case UserType.Fan:
        return this.L('::CreatorDonate:VerifyUserType:Fan');
      default:
        return '';
    }
  }
}
