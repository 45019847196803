import { NgModule } from "@angular/core";
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { PasswordModule } from 'primeng/password';
import { MessageService } from "primeng/api";
import { DialogModule } from 'primeng/dialog';
import { MenubarModule } from 'primeng/menubar';
import { BadgeModule } from 'primeng/badge';
import { MenuModule } from 'primeng/menu';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { InputTextModule } from "primeng/inputtext";
import { DropdownModule } from "primeng/dropdown";
import { DialogService } from "primeng/dynamicdialog";
import { TagModule } from 'primeng/tag';
import { InputMaskModule } from 'primeng/inputmask';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from "primeng/inputnumber";
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  imports: [
    ButtonModule,
    ToastModule,
    CheckboxModule,
    PasswordModule,
    DialogModule,
    MenubarModule,
    BadgeModule,
    MenuModule,
    FontAwesomeModule,
    InputTextModule,
    DropdownModule,
    TagModule,
    InputMaskModule,
    SelectButtonModule,
    CalendarModule,
    InputTextareaModule,
    InputNumberModule,
    PaginatorModule,
    ProgressSpinnerModule,
  ],
  exports: [
    ButtonModule,
    ToastModule,
    CheckboxModule,
    PasswordModule,
    DialogModule,
    MenubarModule,
    BadgeModule,
    MenuModule,
    FontAwesomeModule,
    InputTextModule,
    DropdownModule,
    TagModule,
    InputMaskModule,
    SelectButtonModule,
    CalendarModule,
    InputTextareaModule,
    InputNumberModule,
    PaginatorModule,
    ProgressSpinnerModule,
  ],
  providers: [MessageService, DialogService]
})

export class PrimengSharedModule {
  constructor(library: FaIconLibrary) {
    // library.addIcons(faFilm, faBars, faAngleDown, faUser, faCog, faGift);
    library.addIconPacks(fas, far, fab);
  }
}
